<template>
  <div class="Feedback-container">
    <div class="cover"></div>
    <!--  主要内容-->
    <div class="main">
      <div class="header">
        <img src="@/assets/images/icon_dw_w@2x.png" alt="">
        <span>意见反馈</span>
      </div>

      <div class="form">
        <div class="clearfix">
          <div class="custom-kv clearfix" style="float:left;">
            <div class="key">反馈类型：</div>
            <div class="val">
              <el-checkbox-group
                  v-model="checkedTypes"
                  style="margin-top: 9px"
                  @change="checkHandle"
              >
                <el-checkbox v-for="city in types" :label="city" :key="city">{{city}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="clearfix" style="margin-top: 20px">
          <div class="custom-kv clearfix" style="float:left;">
            <div class="key">客户姓名：</div>
            <div class="val">
              <div class="custom-input">
                <input v-model="customer" type="text" placeholder="输入客户姓名">
              </div>
            </div>
          </div>
          <div class="custom-kv clearfix" style="float:left;">
            <div class="key">日期：</div>
            <div class="val">
              <div class="custom-input" style="padding: 0">
                <el-date-picker
                    v-model="date"
                    type="date"
                    disabled
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    style="width: 100%;">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix" style="margin-top: 20px">
          <div class="custom-kv clearfix" style="float:left;">
            <div class="key">联系人姓名：</div>
            <div class="val">
              <div class="custom-input">
                <input v-model="name" type="text" placeholder="输入联系人姓名">
              </div>
            </div>
          </div>
          <div class="custom-kv clearfix" style="float:left;">
            <div class="key">电话：</div>
            <div class="val">
              <div class="custom-input">
                <input v-model="tel" type="text" placeholder="输入电话号码">
              </div>
            </div>
          </div>
        </div>

        <div class="clearfix" style="margin-top: 20px">
          <div class="custom-kv clearfix">
            <div class="key">反馈内容：</div>
            <div class="val">
              <div class="custom-textarea">
                <textarea v-model="content" name="content" id="content" cols="30" rows="10" placeholder="输入反馈内容"></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="submit cur" @click="addFeedback">提交</div>
      </div>
    </div>
    <ReminderDialog3 ref="reminderDialog3" type="意见反馈"></ReminderDialog3>
  </div>
</template>

<script>
const cityOptions = ['业务', '服务', '货品', '政策','打款','发货','对账'];
import ReminderDialog3 from "@/components/dialogs/ReminderDialog3";
export default {
  name: "Feedback",
  data() {
    return {
      checkedTypes: [],
      types: cityOptions,
      customer: "",
      date: "",
      name:"",
      tel:"",
      content:"",
    };
  },
  components: {ReminderDialog3},
  props: {},
  created() {
    this.date= this.getNowFormatDate();
    this.customer=this.$store.state.name

  },
  methods:{
    checkHandle(val) {
      this.checkedTypes.splice(0, this.checkedTypes.length - 1)
    },
    async addFeedback() {
      if (this.checkedTypes.length == 0) return this.$message.error("请选择反馈类型")
      if (!this.customer) return this.$message.error("请输入客户姓名")
      if (!this.date) return this.$message.error("请选择日期")
      if (!this.name) return this.$message.error("请输入联系人姓名")
      if (!this.tel) return this.$message.error("请输入电话号码")
      if (!this.content) return this.$message.error("请输入反馈内容")
      this.showLoading("加载中...");
      let res = await this.$api.addFeedback({
        type: this.checkedTypes[0],
        customer: this.customer,
        date: this.date,
        name: this.name,
        tel: this.tel,
        content: this.content,
      });
      this.closeLoading();
      if (res.code == 1) {
        this.checkedTypes= [];
        this.name="";
        this.tel="";
        this.content="";
        this.$refs.reminderDialog3.open()
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.Feedback-container {
  min-height: $main-height;

  .cover {
    height: 200px;
    background: url("../assets/images/bg_img@2x.png") center;
    background-size: auto 100%;
    @media (max-width:1600px){
      height: 120px;
    }
  }

  .main {
    width: 1200px;
    margin: 20px auto;

    > .header {
      height: 60px;
      background: $color-primary;
      padding-left: 20px;
      position: relative;

      img {
        width: 20px;
        @include cv;
      }

      span {
        line-height: 60px;
        margin-left: 30px;
        font-size: 16px;
        color: #FFFFFF;
      }
    }

    > .form {
      padding-top: 26px;
      padding-left: 50px;
      //padding-bottom: 100px;
      .submit{
        text-align: center;
        width: 123px;
        height: 40px;
        line-height: 40px;
        background: #D70D18;

        font-size: 14px;
        color: #FFFFFF;
        margin-top: 40px;
        margin-left: 130px;
      }

      .custom-kv {
        .key{
          float: left;
          width: 130px;
          font-size: 14px;
          color: #333333;
          text-align: right;
          margin-top: 13px;
          padding-right: 15px;
        }
        .val{
          float: left;
          .custom-input{
            width: 348px;
            height: 40px;
            border: 1px solid #DADADA;
            padding: 0 20px;
            input[type="text"]{
              height: 100%;
              width: 100%;
              padding: 0;
              border: none;
              outline: none;
            }
            ::v-deep.el-input__inner {
              outline: none;
              border: none;
              height: 38px;
              line-height: 38px;
              padding-left: 20px;
            }
            ::v-deep .el-icon-date{
              display: none;
            }
          }
          .custom-textarea{
            width: 828px;
            height: 116px;
            border: 1px solid #DADADA;
            textarea{
              height: 100%;
              width: 100%;
              padding: 10px 20px;
              box-sizing: border-box;
              border: none;
              outline: none;
            }
          }
          ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
            background-color: $color-primary;
            border-color: $color-primary;
          }
          ::v-deep.el-checkbox__inner:hover{
            border-color: $color-primary;
          }
          ::v-deep.el-checkbox__input.is-checked+.el-checkbox__label{
            color: #333333;
          }

        }
      }
    }

  }

}
</style>